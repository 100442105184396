import Vue from "vue";
import Vuex from "vuex";
import { login } from '@/api/user'
// import { reject } from "core-js/fn/promise";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('TOKEN') || 0
    },
    getters: {
        token: state => state.token
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        CLEAR_TOKEN: (state) => {
            state.token = 0
            localStorage.removeItem('TOKEN')
        }
    },
    actions: {
        Login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(res => {
                    const data = res.data
                    localStorage.setItem('TOKEN', data.token, 7 * (60 * 60 * 24) * 1000)
                    commit('SET_TOKEN', data.token)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        }
    },
    modules: {},
});
