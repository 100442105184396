import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import flexible from './assets/flexible.min.js'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view';
import "../src/assets/reset.css";
import "../src/assets/global.css";
import rem from './assets/rem'
import './permission'

// import 'lib-flexible'

import *  as loopShowTooltip from "./utils/tooltip-auto-show-vue" // 文件位置参考自己的位置
Vue.prototype.loopShowTooltip = loopShowTooltip

// 无缝滚动插件
import scroll from 'vue-seamless-scroll'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
// Vue.use(flexible)

Vue.use(ElementUI)
Vue.use(scroll)
Vue.use(rem)
// Vue.component('newVideo', newVideo)
//or you can set componentName default componentName is vue-seamless-scroll
Vue.use(scroll, { componentName: 'scroll-seamless' })

Vue.use(dataV),

    Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
