
//对于axios进行封装
import store from '@/store' // 引入vuex store实例
import axios from "axios";

//1.利用axios对象的方法create，去创建一个axios实例
const apis = {
    // production: 'https://jifen.nyyiot.com/admin/', // 线上 (生成环境)
    production: '/api/admin/', // 线上 (生成环境)
    development: '/admin/', // 本地 (开发环境)
}
//2.requst就是axios,只不过稍微配置一下
const requests = axios.create({
    //配置对象
    //基础路径，发请求的时候。路径当中会出现APi
    // baseURL: "https://h5.ybit100.com/api/",  //线上
    // baseURL: "http://jifen.nyyiot.com//admin",  //开发环境
    baseURL: process.env.NODE_ENV === 'production' ? apis.production : apis.development,  //开发环境
    //请求头
    headers: {
        siteid: 49
    },
    //代表请求超时时间50s
    timeout: 50000,
});
//请求拦截器：在发请求之前，请求拦截器是可以检测到的，可以在请求发出去之前做一些事情
requests.interceptors.request.use((config) => {
    //config :配置对象，对象里面有一个属性很重要，header请求头
    if (store.getters.token) {
        // 如果token存在 注入token
        config.headers['Access-Token'] = `${store.getters.token}`
    }
    return config;
});

//相应拦截器
requests.interceptors.response.use((res) => {
    //成功的回调函数：服务器响应数据回来之后，响应拦截器是可以检测到的，可以做一些事情
    if (res.data.status == '401') {
        store.commit('CLEAR_TOKEN')
        window.location.href = '/login'
    }
    return res.data;
}, (error) => {
    //响应失败的回调函数
    return Promise.reject(new Error('faile'));
});
//对外暴露

export default requests;
