import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "index",
        component: () => import('../views/index.vue'),
    }, {
        path: "/login",
        name: "login",
        component: () => import('../views/login.vue'),
    }, {
        path: "*",
        name: "404",
        component: () => import('../views/404.vue'),
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;
